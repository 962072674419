import React from 'react'
import Product from '../components/ProductCard/ProductCard'
import Breadcrumbs from '../components/Breadcrumbs/CategoryCrumbs'
import { Box, Grid, Container, Card } from 'theme-ui'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Main } from '@layout'
import PageTitle from '@components/PageTitle'
import { SidebarMenu } from '../components/SidebarMenu/SidebarMenu'
import { SidebarLeft } from '../layout/SidebarLeft'
import Section from '@components/Section'

export default props => {
  const catId = props.data.category.id
  const catName = props.data.category.name
  const products = props.data.category.products
  const count = props.data.category.count

  const styles = {
    center: {
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center'
    },
    breadcrumbs: {
      textTransform: 'uppercase',
      fontSize: '1.25em'
    },
    link: {
      textDecoration: 'none'
    },
    flex: {
      display: 'flex'
    },
    sidebar: {
      display: ['none', 'none', 'none', 'block'],
      minWidth: `auto`
    },
    sidecard: {
      marginTop: -25
    }
  }

  return (
    <>
      <Seo
        title={
          'All Products in ' + catName + ' Category Available for Private Label'
        }
        description={
          "All of Pacific Beauty's products in the " +
          catName +
          ' category are available for private label. View our products and learn how you can get started today.'
        }
      />
      <Divider />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs catName={catName} />
      </Container>
      <Divider />
      <Container variant='wide' sx={styles.flex}>
        <SidebarLeft sx={styles.sidebar}>
          <Section title='Product Menu'>
            <Card variant='paper' sx={styles.sidecard}>
              <SidebarMenu />
            </Card>
          </Section>
        </SidebarLeft>
        <Main>
          <Divider space={2} />
          <PageTitle header={catName} totalCount={count} />
          <Grid gap={3} columns={[1, 2, 3, 3]} sx={styles.center}>
            {products.map(product =>
              product.status === 'publish' ? (
                <Box key={catId + product.id + catName + product.name}>
                  <Product data={product} />
                </Box>
              ) : null
            )}
          </Grid>
        </Main>
      </Container>
    </>
  )
}
