import React from 'react'
import Navigation from '../Navigation'
import { useProductCategories } from '../../helpers'

export const SidebarMenu = () => {
  const categories = useProductCategories()

  const sidebarMenu = (
    <Navigation
      variant='vertical'
      headingProps={{ variant: 'h3' }}
      items={[
        {
          items: categories
        }
      ]}
    />
  )

  return <>{sidebarMenu}</>
}
