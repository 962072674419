import { graphql } from 'gatsby'
import ProductCategoryPage from '../containers/ProductCategory'

export default ProductCategoryPage

export const pageQuery = graphql`
  query ProductCategoryPageQuery2($id: String!) {
    category: wcProductsCategories(id: { eq: $id }) {
      id
      name
      count
      products {
        id
        name
        slug
        images {
          localFile {
            childImageSharp {
              fluid(cropFocus: CENTER, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        status
      }
    }
  }
`
